var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("permission-password", {
            staticStyle: { "z-index": "9999999999" },
            attrs: {
              show: _vm.popupPermissionPassword,
              title: "ALERTA DE BAIXA RETROATIVA",
              icon: "warning",
              actionButtonText: "Prosseguir",
            },
            on: {
              action: function ($event) {
                return _vm.save(true)
              },
              cancel: function ($event) {
                _vm.popupPermissionPassword = false
              },
            },
          }),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "w-full" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-0",
                      attrs: { label: "Nota Fiscal" },
                      model: {
                        value: _vm.fuelSupply.nota_fiscal,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "nota_fiscal", $$v)
                        },
                        expression: "fuelSupply.nota_fiscal",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        type: "date",
                        label: "Realização",
                        "data-vv-as": "Realização",
                        name: "date_of_supply",
                      },
                      model: {
                        value: _vm.fuelSupply.date_of_supply,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "date_of_supply", $$v)
                        },
                        expression: "fuelSupply.date_of_supply",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("date_of_supply"),
                            expression: "errors.has('date_of_supply')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("date_of_supply")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required|min_value:0",
                          expression: "'required|min_value:0'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        type: "number",
                        min: "0",
                        label: "KM Atual",
                        "data-vv-as": "KM Atual",
                        name: "current_mileage",
                      },
                      model: {
                        value: _vm.fuelSupply.current_mileage,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "current_mileage", $$v)
                        },
                        expression: "fuelSupply.current_mileage",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("current_mileage"),
                            expression: "errors.has('current_mileage')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Mínimo 0")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required|min_value:0",
                          expression: "'required|min_value:0'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        type: "number",
                        label: "Litros",
                        "data-vv-as": "Litros",
                        name: "liters",
                      },
                      model: {
                        value: _vm.fuelSupply.liters,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "liters", $$v)
                        },
                        expression: "fuelSupply.liters",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("liters"),
                            expression: "errors.has('liters')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Mínimo 0")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Combustível"),
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      attrs: {
                        reduce: (option) => option.value,
                        "data-vv-as": "Combustível",
                        name: "type_of_fuel",
                        clearable: false,
                        options: [
                          { value: "Gasolina", label: "Gasolina" },
                          { value: "Álcool", label: "Álcool" },
                          { value: "Disel", label: "Disel" },
                          { value: "Gás natural", label: "Gás natural" },
                          { value: "Eletricidade", label: "Eletricidade" },
                        ],
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Desculpe, nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.fuelSupply.type_of_fuel,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "type_of_fuel", $$v)
                        },
                        expression: "fuelSupply.type_of_fuel",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("type_of_fuel"),
                            expression: "errors.has('type_of_fuel')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("type_of_fuel")))]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "w-full" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Responsável"),
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      attrs: {
                        reduce: (option) => option.value,
                        "data-vv-as": "Responsável",
                        name: "employee_id",
                        clearable: true,
                        options: _vm.employees,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Desculpe, nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.fuelSupply.employee_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "employee_id", $$v)
                        },
                        expression: "fuelSupply.employee_id",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("employee_id"),
                            expression: "errors.has('employee_id')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("employee_id")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Fornecedor"),
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      attrs: {
                        clearable: true,
                        filterable: false,
                        reduce: (option) => option.value,
                        options: _vm.providerOptions,
                        "data-vv-as": "Fornecedor",
                        name: "provider_id",
                        placeholder: "Digite CPF/CNPJ ou nome do fornecedor...",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        search: _vm.debouncedGetSearchProvider,
                        "option:selected": _vm.selectedProvider,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.fuelSupply.provider_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "provider_id", $$v)
                        },
                        expression: "fuelSupply.provider_id",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("provider_id"),
                            expression: "errors.has('provider_id')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("provider_id")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Total"),
                    ]),
                    _c("currency-input", {
                      staticClass:
                        "w-full vs-inputx vs-input--input normal hasValue",
                      attrs: {
                        currency: { prefix: "R$ " },
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        autoDecimalMode: true,
                        valueRange: { min: 0 },
                        allowNegative: false,
                      },
                      model: {
                        value: _vm.fuelSupply.value_total,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "value_total", $$v)
                        },
                        expression: "fuelSupply.value_total",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Descrição" },
                  model: {
                    value: _vm.fuelSupply.observation,
                    callback: function ($$v) {
                      _vm.$set(_vm.fuelSupply, "observation", $$v)
                    },
                    expression: "fuelSupply.observation",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "DollarSignIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Pagamento"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-2" }),
          _vm._l(_vm.debit.transactions, function (parcel, i) {
            return _c("div", { key: i, staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: !parcel.expiration_date },
                        expression: "{ required: !parcel.expiration_date }",
                      },
                    ],
                    attrs: {
                      type: "date",
                      label: "Vencimento",
                      "data-vv-as": "Vencimento",
                      "data-vv-scope": "debit",
                      name: "expiration_date" + i,
                    },
                    model: {
                      value: parcel.expiration_date,
                      callback: function ($$v) {
                        _vm.$set(parcel, "expiration_date", $$v)
                      },
                      expression: "parcel.expiration_date",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("debit.expiration_date" + i),
                          expression: "errors.has('debit.expiration_date'+i)",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Campo obrigatório")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: { required: parcel.situation },
                        expression: "{ required: parcel.situation }",
                        modifiers: { initial: true },
                      },
                    ],
                    attrs: {
                      type: "date",
                      label: "Pago em",
                      disabled: !parcel.situation,
                      "data-vv-as": "Pago em",
                      "data-vv-scope": "debit",
                      name: "payday" + i,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.checkPayday(parcel)
                      },
                    },
                    model: {
                      value: parcel.payday,
                      callback: function ($$v) {
                        _vm.$set(parcel, "payday", $$v)
                      },
                      expression: "parcel.payday",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("debit.payday" + i),
                          expression: "errors.has('debit.payday'+i)",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Campo obrigatório")]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: parcel.futureDate,
                          expression: "parcel.futureDate",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Data futura não permitida")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "vx-col md:w-1/6 w-full" }, [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Situação"),
                ]),
                _c(
                  "div",
                  { staticClass: "flex flex-wrap items-center justify-center" },
                  [
                    _c(
                      "vs-switch",
                      {
                        staticClass: "w-28 mt-2",
                        attrs: {
                          "icon-pack": "feather",
                          "vs-icon-on": "icon-check-circle",
                          "vs-icon-off": "icon-slash",
                        },
                        on: {
                          input: function ($event) {
                            parcel.situation
                              ? (parcel.value_pay = parcel.value_parcel)
                              : (parcel.value_pay = 0)
                            parcel.situation
                              ? (parcel.payday = _vm.today)
                              : (parcel.payday = "")
                            parcel.situation ? "" : (parcel.futureDate = false)
                            _vm.checkPayday(parcel)
                          },
                        },
                        model: {
                          value: parcel.situation,
                          callback: function ($$v) {
                            _vm.$set(parcel, "situation", $$v)
                          },
                          expression: "parcel.situation",
                        },
                      },
                      [
                        _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                          _vm._v("ABERTA"),
                        ]),
                        _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                          _vm._v("QUITADA"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Forma Pagamento"),
                  ]),
                  _c("v-select", {
                    staticClass: "vue_select_drop_size_100 w-48",
                    attrs: {
                      clearable: false,
                      reduce: (option) => option.value,
                      options: _vm.typePayments,
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.filterAccountsParcels(i)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n            Nenhum resultado encontrado.\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: parcel.type_payment_id,
                      callback: function ($$v) {
                        _vm.$set(parcel, "type_payment_id", $$v)
                      },
                      expression: "parcel.type_payment_id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Conta"),
                  ]),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: { required: !parcel.account_id },
                        expression: "{ required: !parcel.account_id }",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "vue_select_drop_size_100 w-48",
                    attrs: {
                      clearable: false,
                      disabled: !parcel.type_payment_id,
                      reduce: (option) => option.value,
                      options: parcel.accountsFilteredOptions,
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      "data-vv-as": "Conta",
                      "data-vv-scope": "debit",
                      name: "account_id" + i,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n            Nenhum resultado encontrado.\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: parcel.account_id,
                      callback: function ($$v) {
                        _vm.$set(parcel, "account_id", $$v)
                      },
                      expression: "parcel.account_id",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("debit.account_id" + i),
                          expression: "errors.has('debit.account_id'+i)",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Campo obrigatório")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Plano de Conta"),
                  ]),
                  _c("v-select", {
                    staticClass: "vue_select_drop_size_100 w-48",
                    attrs: {
                      clearable: false,
                      reduce: (option) => option.value,
                      options: _vm.accountPlans,
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n            Nenhum resultado encontrado.\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: parcel.account_plan_id,
                      callback: function ($$v) {
                        _vm.$set(parcel, "account_plan_id", $$v)
                      },
                      expression: "parcel.account_plan_id",
                    },
                  }),
                ],
                1
              ),
            ])
          }),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }