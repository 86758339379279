<template>
  <div id="employee-new-modal">
    <vs-popup class="popup90" :title="title" :active.sync="localShow">

      <permission-password style="z-index: 9999999999;" :show="popupPermissionPassword" title="ALERTA DE BAIXA RETROATIVA" icon="warning"
        actionButtonText="Prosseguir"
        @action="save(true)"
        @cancel="popupPermissionPassword = false">
      </permission-password>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input class="w-full mt-0" label="Nota Fiscal" v-model="fuelSupply.nota_fiscal" />
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input type="date" class="w-full mt-0" label="Realização" v-model="fuelSupply.date_of_supply" data-vv-as="Realização" v-validate.initial="'required'" name="date_of_supply" />
              <span class="text-danger text-sm" v-show="errors.has('date_of_supply')">{{ errors.first('date_of_supply') }}</span>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input type="number" min="0" class="w-full mt-0" label="KM Atual" v-model="fuelSupply.current_mileage"
                data-vv-as="KM Atual" v-validate.initial="'required|min_value:0'" name="current_mileage" />
              <span class="text-danger text-sm" v-show="errors.has('current_mileage')">Mínimo 0</span>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input type="number" class="w-full mt-0" label="Litros" v-model="fuelSupply.liters"
                data-vv-as="Litros" v-validate.initial="'required|min_value:0'" name="liters" />
              <span class="text-danger text-sm" v-show="errors.has('liters')">Mínimo 0</span>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <label class="vs-input--label">Combustível</label>
              <v-select v-model="fuelSupply.type_of_fuel" :reduce="option => option.value"
                data-vv-as="Combustível" v-validate.initial="'required'" name="type_of_fuel" :clearable="false"
                :options="[ { value: 'Gasolina',     label: 'Gasolina' },
                            { value: 'Álcool',       label: 'Álcool' },
                            { value: 'Disel',        label: 'Disel' },
                            { value: 'Gás natural',  label: 'Gás natural' },
                            { value: 'Eletricidade', label: 'Eletricidade' }
                ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('type_of_fuel')">{{ errors.first('type_of_fuel') }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Responsável</label>
              <v-select v-model="fuelSupply.employee_id" :reduce="option => option.value"
                data-vv-as="Responsável" v-validate.initial="'required'" name="employee_id"
                :clearable="true" :options="employees" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('employee_id')">{{ errors.first('employee_id') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Fornecedor</label>
              <v-select v-model="fuelSupply.provider_id" @search="debouncedGetSearchProvider" @option:selected="selectedProvider" :clearable="true" :filterable="false"
                :reduce="option => option.value" :options="providerOptions" data-vv-as="Fornecedor" v-validate.initial="'required'" name="provider_id"
                placeholder="Digite CPF/CNPJ ou nome do fornecedor..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('provider_id')">{{ errors.first('provider_id') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Total</label>
              <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
                  v-model="fuelSupply.value_total"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-full w-full">
          <vs-input class="w-full mt-2" label="Descrição" v-model="fuelSupply.observation" />
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="DollarSignIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Pagamento</span>
      </div>
      <hr class="mb-2">

      <!-- PARCELAS -->

      <div class="vx-row" :key="i" v-for="(parcel, i) in debit.transactions">
        <div class="vx-col md:w-1/6 w-full">
          <vs-input type="date" label="Vencimento" v-model="parcel.expiration_date"
            data-vv-as="Vencimento" data-vv-scope="debit" v-validate="{ required: !parcel.expiration_date }" :name="'expiration_date'+i" />
          <span class="text-danger text-sm" v-show="errors.has('debit.expiration_date'+i)">Campo obrigatório</span>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <vs-input type="date" label="Pago em" :disabled="!parcel.situation" v-model="parcel.payday" @change="checkPayday(parcel)"
          data-vv-as="Pago em" data-vv-scope="debit" v-validate.initial="{ required: parcel.situation }" :name="'payday'+i" />
          <span class="text-danger text-sm" v-show="errors.has('debit.payday'+i)">Campo obrigatório</span>
          <span class="text-danger text-sm" v-show="parcel.futureDate">Data futura não permitida</span>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <label class="vs-input--label">Situação</label>
          <div class="flex flex-wrap items-center justify-center">
            <vs-switch class="w-28 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash"
              @input="parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0;
              parcel.situation ? parcel.payday = today : parcel.payday = ''
              parcel.situation ? '' : parcel.futureDate = false;
              checkPayday(parcel)" v-model="parcel.situation">
              <span slot="off">ABERTA</span> <span slot="on">QUITADA</span>
            </vs-switch>
          </div>
        </div>

        <div class="vx-col md:w-1/6 w-full">
          <label class="vs-input--label">Forma Pagamento</label>
          <v-select class="vue_select_drop_size_100 w-48" :clearable="false" @input="filterAccountsParcels(i)" v-model="parcel.type_payment_id" :reduce="option => option.value" :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <label class="vs-input--label">Conta</label>
          <v-select class="vue_select_drop_size_100 w-48" :clearable="false" :disabled="!parcel.type_payment_id" v-model="parcel.account_id"
            :reduce="option => option.value" :options="parcel.accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"
            data-vv-as="Conta" data-vv-scope="debit" v-validate.initial="{ required: !parcel.account_id }" :name="'account_id'+i">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('debit.account_id'+i)">Campo obrigatório</span>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <label class="vs-input--label">Plano de Conta</label>
          <v-select class="vue_select_drop_size_100 w-48" :clearable="false" v-model="parcel.account_plan_id" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save()" :disabled="!validateForm">Cadastrar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import _ from 'lodash'
import moment from 'moment'
import { CurrencyInput } from 'vue-currency-input'
const permissionPassword = () => import(/* webpackChunkName: "permissionPassword" */ '@/components/permission-password/permissionPassword.vue')

import moduleEmployee           from '@/store/employee/moduleEmployee.js'
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import moduleAccount from '@/store/account/moduleAccount.js'
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'

export default {
  components: {
    vSelect,
    CurrencyInput,
    permissionPassword
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    vehicle: {
      Object, required: true
    }
  },

  computed: {
    serviceMaintenances () {
      return this.$store.getters['serviceMaintenance/forSelect']
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    validateForm () {
      return !this.errors.any()
    },
    firstValue () { //controlo no watch para não permitir valor maior que o total
      return this.debit.firstParcelValue
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterDespesa']
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    }
  },

  data () {
    return {
      localShow: false,
      providerOptions: [],
      fuelSupply: {
        nota_fiscal: null,
        date_of_supply: '',
        current_mileage: 0,
        liters: 0,
        type_of_fuel: null,
        employee_id: null,
        provider_id: null,
        value_total: 0,
        observation: null,
        vehicle: this.vehicle
      },

      accountsFilteredOptions: [],
      popupPermissionPassword: false,
      currencyConfig:  {
        currency: {prefix: 'R$ '},
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      },

      debit: {
        transactions: [
          {
            futureDate: false,
            type: 'DEBIT',
            expiration_date: this.today, //this.expiration_date.toISOString().slice(0, 10),
            payday: '',
            value_pay: 0,
            parcel: 1,
            value_parcel: 0,
            situation: false, // false = PENDING | true = PAID
            value_total: 0,
            observation: null,
            account_plan_id: null,
            account_id: null,
            type_payment_id: null,
            accountsFilteredOptions: this.accountsFilteredOptions
          }
        ]
      }
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      this.$validator.pause()
      //COMO OS CAMPOS APARECEM E SOMEM DINAMICAMENTE, PRECISO DO BLOCO ABAIXO PARA EVITAR ERROS DE VALIDAÇÃO
      this.$nextTick(() => {
        this.$validator.resume()
        this.$validator.errors.clear()
        this.$validator.validateScopes()
      })

      if (!val) {
        this.fuelSupply = {
          nota_fiscal: null,
          date_of_supply: '',
          current_mileage: 0,
          liters: 0,
          type_of_fuel: null,
          employee_id: null,
          provider_id: null,
          value_total: 0,
          observation: null,
          vehicle: this.vehicle
        }

        this.debit = {
          transactions: [
            {
              futureDate: false,
              type: 'DEBIT',
              expiration_date: this.today, //this.expiration_date.toISOString().slice(0, 10),
              payday: '',
              value_pay: 0,
              parcel: 1,
              value_parcel: 0,
              situation: false, // false = PENDING | true = PAID
              value_total: 0,
              observation: null,
              account_plan_id: null,
              account_id: null,
              type_payment_id: null,
              accountsFilteredOptions: this.accountsFilteredOptions
            }
          ]
        }
        this.$emit('cancel')
      } else {
        this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('accountPlan/fetchAll').then(() => { }).catch(err => { console.error(err) })

        this.fuelSupply = JSON.parse(JSON.stringify(this.$store.getters['fuelSupply/get']))
        this.providerOptions.splice(0)
        this.providerOptions.push({
          value: this.fuelSupply.provider.id,
          label: `${this.fuelSupply.provider.name} ${this.fuelSupply.provider.cpf}`,
          account_plan_id: this.fuelSupply.provider.account_plan_id
        })
        this.debit.parcelsNumber = 1

        this.$store.dispatch('account/fetchAll').then(() => {
          this.debit.transactions = [this.fuelSupply.transaction]
          this.debit.transactions.forEach((element, i) => {
            this.filterAccountsParcels(i)
          })
        }).catch(err => { console.error(err) })


      }
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    // SELECIONA O PLANO DE CONTAS CONFORME O PROVIDER
    selectedProvider () {
      const provider = this.providerOptions.find(e => e.value === this.fuelSupply.provider_id)
      if (provider) this.debit.transactions[0].account_plan_id = provider.account_plan_id
    },

    //EVITA BAIXA COM DATA FUTURA
    checkPayday (parcel) {
      if (moment(parcel.payday).year() > 1000) {
        const difDays = moment(parcel.payday).diff(moment(this.today), 'days')
        if (difDays > 0) {
          parcel.futureDate = true
          this.disableSave = true
        } else {
          parcel.futureDate = false
          //checa se há alguma parcela com pagamento futuro
          const index = this.debit.transactions.findIndex(e => e.futureDate)
          if (index === -1) this.disableSave = false
        }
      } else {
        parcel.futureDate = false
        //checa se há alguma parcela com pagamento futuro
        const index = this.debit.transactions.findIndex(e => e.futureDate)
        if (index === -1) this.disableSave = false
      }
    },

    isSameOrAfterToday (e) {
      if (moment(e).isValid()) return moment(e).isSameOrAfter(moment().format('YYYY-MM-DD'))
      return true
    },

    fetchproviderOptions (search, loading) {
      if (search.length) {
        loading(true)
        // busco alunos e fornecedores
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: 'PROVIDER' })
          .then(function (response) {
            this.providerOptions = []
            for (const k in response.data) {
              this.providerOptions.push({
                value: response.data[k].id,
                label: `${response.data[k].name} ${response.data[k].cpf || ''}`,
                account_plan_id: response.data[k].account_plan_id
              })
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    async save (pass = false) {
      try {
        /**
         * CHECAR PAGAMENTO RETROATIVO
         */
        if (!pass) {
          const retroactiveParcels = this.debit.transactions.filter(e => {
            if (moment(e.payday).diff(moment(this.today), 'days') < 0) return true
          })

          if (retroactiveParcels.length) {
            this.popupPermissionPassword = true
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: 'Há parcelas com baixa retroativa',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
            return
          }
        }

        this.$vs.loading()
        await this.$store.dispatch('fuelSupply/update', { fuelSupply: this.fuelSupply, transaction: this.debit })
        this.$vs.loading.close()
        this.action()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.localShow = false
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // FILTRA AS CONTAS CONFORME O TIPO DE PAGAMENTO
    filterAccounts () {
      this.debit._id = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === this.debit.type_payment_id)
      })
      this.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) this.debit.account_id = myArrayFiltered[0].value
    },
    // FILTRA AS CONTAS NO FOR DAS PARCELAS CONFORME O TIPO DE PAGAMENTO
    filterAccountsParcels (i) {
      const parcel = this.debit.transactions[i]
      parcel.account = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === parcel.type_payment_id)
      })
      parcel.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) parcel.account_id = myArrayFiltered[0].value
    }
  },
  created () {
    this.debouncedGetSearchProvider = _.debounce(this.fetchproviderOptions, 600)

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    if (!moduleAccount.isRegistered) {
      // this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }

  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup90 .vs-popup {
    width: 90% !important;
  }

  .vue_select_drop_size_160 .vs__dropdown-menu {
    max-height: 160px;
  }
  .vs-tooltip {
    z-index: 99999;
  }
</style>
